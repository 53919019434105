import { KeycloakTokenParsed } from "keycloak-js/lib/keycloak";
import { toast } from "react-toastify";

const WHAT_ENDPOINT =
  "/indykite.authorization.v1beta1.AuthorizationAPI/WhatAuthorized";
const UPSERT_NODES_ENDPOINT =
  "/indykite.ingest.v1beta3.IngestAPI/BatchUpsertNodes";
const UPSERT_RELATIONSHIPS_ENDPOINT =
  "/indykite.ingest.v1beta3.IngestAPI/BatchUpsertRelationships";
const IDENTITY_KNOWLEDGE_READ_ENDPOINT =
  "/indykite.knowledge.v1beta2.IdentityKnowledgeAPI/IdentityKnowledgeRead";
const DELETE_NODES_ENDPOINT =
  "/indykite.ingest.v1beta3.IngestAPI/BatchDeleteNodes";

export const ingestPersonNode = async (data: any) => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const credToken = credential.token;
    if (!credToken) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const tokenParsed: KeycloakTokenParsed = data.tokenParsed;
    if (!tokenParsed) {
      toast("invalid tokenParsed", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const personExternalId: string = data.personExternalId;
    if (!personExternalId) {
      toast("invalid personExternalId", {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const payload = {
      nodes: [
        {
          external_id: tokenParsed.email,
          type: `Person`,
          tags: [],
          isIdentity: true,
          properties: [
            {
              type: "email",
              value: {
                stringValue: tokenParsed.email,
              },
              metadata: {
                assuranceLevel: 2,
                source: "KeycloakIDP",
                verificationTime: new Date().toISOString(),
              },
            },
            {
              type: "name",
              value: {
                stringValue: tokenParsed.name,
              },
              metadata: {
                assuranceLevel: 2,
                source: "KeycloakIDP",
                verificationTime: new Date().toISOString(),
              },
            },
            {
              type: "phone",
              value: {
                string_value: tokenParsed.preferred_username,
              },
              metadata: {
                assuranceLevel: 2,
                source: "KeycloakIDP",
                verificationTime: new Date().toISOString(),
              },
            },
          ],
        },
      ],
    };

    const url = baseUrl + UPSERT_NODES_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error("Could not ingest data.");
    }
    toast("Person node ingested", {
      type: "success",
      position: "top-right",
    });
  } catch (e) {
    console.error(e);
    toast("Error ingesting person", {
      type: "error",
      position: "top-right",
    });
  }
};
export const ingestRelationship = async (data: any) => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const credToken = credential.token;
    if (!credToken) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const personExternalId: string = data.personExternalId;
    if (!personExternalId) {
      toast("invalid personExternalId", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const accountExternalId: string = data.accountExternalId;
    if (!accountExternalId) {
      toast("invalid accountExternalId", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const payload = {
      relationships: [
        {
          source: {
            external_id: personExternalId,
            type: "Person",
          },
          target: {
            external_id: accountExternalId,
            type: "Account",
          },
          type: "HAS",
        },
      ],
    };

    const url = baseUrl + UPSERT_RELATIONSHIPS_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error("Could not ingest data.");
    }
    toast("Person Account relationship ingested", {
      type: "success",
      position: "top-right",
    });
  } catch (e) {
    console.error(e);
    toast("Error Person Account relationship ingested", {
      type: "error",
      position: "top-right",
    });
  }
};

export const perfomWhatAuthorized = async (data: any): Promise<void> => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const credToken = credential.token;
    if (!credToken) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }

    const token: string = data.token;
    if (!token) {
      toast("invalid token", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const payload = {
      subject: {
        accessToken: `${token}`,
      },
      resource_types: [
        {
          type: `Person`,
          actions: [`EXISTS`],
        },
      ],
    };

    const url = baseUrl + WHAT_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credToken}`,
      },
    });
    if (response.status !== 200) {
      throw new Error("Could not read data.");
    }
    toast("Token introspected and ingested", {
      type: "success",
      position: "top-right",
    });
  } catch (e) {
    console.error(e);
    toast("Error performing authorization", {
      type: "error",
      position: "top-right",
    });
  }
};

interface Profile {
  externalId: string;
  type: string;
  name: string;
}

export const getProfiles = async (data: any): Promise<Profile[]> => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return [];
    }
    const credToken = credential.token;
    if (!credToken) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return [];
    }

    const subject: string = data.subject;
    if (!subject) {
      toast("invalid subject", {
        type: "error",
        position: "top-right",
      });
      return [];
    }
    const payload = {
      query:
        "MATCH (a:Account)<-[:HAS]-(:Person)-[:HAS]->(profile) WHERE a.external_id = $externalId AND (profile:ConsumerProfileUnox OR profile:ConsumerProfileRema)",
      inputParams: {
        externalId: {
          stringValue: subject,
        },
      },
      returns: [
        {
          variable: "profile",
          properties: [],
        },
      ],
    };

    const url = baseUrl + IDENTITY_KNOWLEDGE_READ_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Could not read data.");
    }

    const result = await response.json();
    if (!result.nodes) {
      return [];
    }

    return result.nodes.map((node: any) => {
      let name = "Unknown";

      if (node.type === "ConsumerProfileRema") {
        const nameProperty = node.properties.find(
          (prop: any) => prop.type === "name"
        );
        name = nameProperty?.value?.stringValue || "Unknown";
      } else if (node.type === "ConsumerProfileUnox") {
        const firstNameProperty = node.properties.find(
          (prop: any) => prop.type === "firstname"
        );
        const lastNameProperty = node.properties.find(
          (prop: any) => prop.type === "lastname"
        );

        const firstName = firstNameProperty?.value?.stringValue || "";
        const lastName = lastNameProperty?.value?.stringValue || "";

        name = [firstName, lastName].filter(Boolean).join(" ") || "Unknown";
      }

      return {
        externalId: node.externalId,
        type: node.type,
        name,
      };
    });
  } catch (e) {
    console.error(e);
    toast("Error reading profiles", {
      type: "error",
      position: "top-right",
    });
    return [];
  }
};

interface Vehicle {
  externalId: string;
  type: string;
}

const getVehicles = async (
  baseUrl: string,
  credToken: string,
  externalId: string
): Promise<Vehicle[]> => {
  const payload = {
    query:
      "MATCH (p:ConsumerProfileUnox)-[:OWNS]->(v:Vehicle) WHERE p.external_id = $external_id",
    input_params: {
      external_id: {
        string_value: externalId,
      },
    },
    returns: [
      {
        variable: "v",
        properties: [],
      },
    ],
  };

  const response = await fetch(baseUrl + IDENTITY_KNOWLEDGE_READ_ENDPOINT, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${credToken}`,
    },
  });

  if (response.status !== 200) {
    throw new Error("Could not fetch vehicles.");
  }

  const result = await response.json();
  if (!result.nodes) {
    return [];
  }
  return result.nodes.map((node: any) => ({
    externalId: node.externalId,
    type: node.type,
  }));
};

export const deleteMe = async (data: any): Promise<void> => {
  try {
    const credential = JSON.parse(data.credential);
    const baseUrl = credential.baseUrl;
    if (!baseUrl) {
      toast("baseUrl not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }
    const credToken = credential.token;
    if (!credToken) {
      toast("token not found in credential config", {
        type: "error",
        position: "top-right",
      });
      return;
    }

    let nodesToDelete = [
      {
        external_id: data.externalId,
        type: data.type,
      },
    ];

    // If ConsumerProfileUnox, fetch and include vehicles
    if (data.type === "ConsumerProfileUnox") {
      try {
        const vehicles = await getVehicles(baseUrl, credToken, data.externalId);

        nodesToDelete = [
          ...nodesToDelete,
          ...vehicles.map((vehicle) => ({
            external_id: vehicle.externalId,
            type: vehicle.type,
          })),
        ];
      } catch (error) {
        console.error("Error fetching vehicles:", error);
        toast("Error fetching vehicles", {
          type: "error",
          position: "top-right",
        });
        return;
      }
    } else if (data.type !== "ConsumerProfileRema") {
      toast("Only ConsumerProfileRema and ConsumerProfileUnox can be deleted", {
        type: "warning",
        position: "top-right",
      });
      return;
    }

    const payload = {
      nodes: nodesToDelete,
    };

    const url = baseUrl + DELETE_NODES_ENDPOINT;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${credToken}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Could not delete node(s).");
    }

    toast(
      `Profile${
        nodesToDelete.length > 1 ? " and vehicles" : ""
      } successfully deleted`,
      {
        type: "success",
        position: "top-right",
      }
    );
  } catch (e) {
    console.error(e);
    toast("Error deleting profile", {
      type: "error",
      position: "top-right",
    });
  }
};
