import { useEffect, useState } from "react";
import { ContentWrapper, FormWrapper, InnerContentWrapper } from "./styled";
import Title from "../../../library/title";
import useConfiguration from "../../../hooks/useConfiguration";
import {
  ingestPersonNode,
  ingestRelationship,
  perfomWhatAuthorized,
  getProfiles,
  deleteMe,
} from "./data";
import Keycloak from "../../../../node_modules/keycloak-js/lib/keycloak";
import { v4 as uuidv4 } from "uuid";

const keycloak = new Keycloak({
  url: "https://reitan.eu-central-1.aws.auth.ac/auth",
  realm: "test",
  clientId: "reitanretail-poc",
});

interface Profile {
  externalId: string;
  type: string;
  name: string;
}

const Content = () => {
  const { configurations } = useConfiguration();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    keycloak.authenticated || false
  );
  const [selectedCredential, setSelectedCredential] = useState<any>({});
  const [isIngesting, setIsIngesting] = useState(false);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const [deletingProfileId, setDeletingProfileId] = useState<string | null>(
    null
  );

  const ingestToken = async (e: any) => {
    const personExternalId = uuidv4();
    const formData = {
      credential: selectedCredential,
      token: keycloak.token,
    };
    const ingestPersonNodeData = {
      credential: selectedCredential,
      tokenParsed: keycloak.tokenParsed,
      personExternalId: personExternalId,
    };
    const ingestRelationshipData = {
      credential: selectedCredential,
      // personExternalId: personExternalId,
      personExternalId: keycloak.tokenParsed?.email,
      accountExternalId: keycloak.tokenParsed?.sub,
    };

    setIsIngesting(true);
    await perfomWhatAuthorized(formData);
    await ingestPersonNode(ingestPersonNodeData);
    await ingestRelationship(ingestRelationshipData);
    setIsIngesting(false);
  };

  useEffect(() => {
    const initKeycloak = async () => {
      const authenticated = await keycloak.init({
        flow: "implicit",
      });
      if (authenticated) {
        setIsAuthenticated(true);
        // console.log("ID Token:", keycloak.idToken);
        // console.log("Access Token:", keycloak.token);
      }
    };
    if (!keycloak.didInitialize) {
      initKeycloak().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (configurations.length > 0) {
      setSelectedCredential(configurations[0]?.value);
    }
  }, [configurations]);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (keycloak.authenticated && keycloak.tokenParsed?.sub) {
        setIsLoadingProfiles(true);
        try {
          const fetchedProfiles = await getProfiles({
            credential: selectedCredential,
            subject: keycloak.tokenParsed.sub,
          });
          setProfiles(fetchedProfiles);
        } catch (error) {
          console.error("Error fetching profiles:", error);
        } finally {
          setIsLoadingProfiles(false);
        }
      }
    };

    fetchProfiles();
  }, [keycloak.authenticated, keycloak.tokenParsed?.sub, selectedCredential]);

  const LoginButton = () => {
    return (
      <button
        className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300"
        onClick={async () => {
          let loginUrl = await keycloak.createLoginUrl({
            redirectUri: window.location.origin + "/keycloak",
          });
          const profilePickerUrl = encodeURIComponent(
            window.location.origin + "/profile-picker"
          );
          loginUrl += "&profilePicker=" + profilePickerUrl;
          window.location.href = loginUrl;
          // await keycloak.login({
          //   redirectUri: window.location.origin + "/keycloak",
          //   profilePicker: window.location.origin + "/profile-picker",
          // })
        }}
      >
        Log In
      </button>
    );
  };

  const StepUpButton = () => {
    return (
      <button
        className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold flex-1 px-3 py-3 rounded-md bg-[#222222] transition-all duration-300"
        onClick={async () => {
          let loginUrl = await keycloak.createLoginUrl({
            redirectUri: window.location.origin + "/keycloak",
          });
          const profilePickerUrl = encodeURIComponent(
            window.location.origin + "/profile-picker"
          );
          loginUrl +=
            "&prompt=login&kc_idp_hint=auth0-standard&profilePicker=" +
            profilePickerUrl;
          window.location.href = loginUrl;
        }}
      >
        StepUp
      </button>
    );
  };

  const LogoutButton = () => {
    return (
      <button
        className="flex-shrink bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-3 rounded-md bg-[#222222] transition-all duration-300 max-w-[100px]"
        onClick={() =>
          keycloak.logout({
            redirectUri: window.location.origin + "/keycloak",
          })
        }
      >
        Log Out
      </button>
    );
  };

  const IngestButton = () => {
    return (
      <button
        className="bg-gradient-to-r from-[#554AD5] to-[#6862AD] text-white font-raleway font-semibold px-3 py-3 rounded-md bg-[#222222] transition-all duration-300 max-w-[200px]"
        onClick={ingestToken}
      >
        {isIngesting ? (
          <div className="flex gap-1 items-center justify-center">
            <i className="fas fa-spinner fa-spin"></i>
            Calling...
          </div>
        ) : (
          <div className="flex justify-center">Call IndyKite</div>
        )}
      </button>
    );
  };

  const Configuration = () => {
    return (
      <div>
        <label
          htmlFor="credential"
          className="text-white font-raleway font-bold"
        >
          Configuration
        </label>
        <div>
          <select
            className="rounded-md border-none bg-[#222222] text-white w-full px-3 py-3"
            id="credential"
            name="credential"
            onChange={(e) => {
              setSelectedCredential(e.target.value);
            }}
            value={selectedCredential}
          >
            {configurations.map((t) => (
              <option key={t.id} value={t.value}>
                {t.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const DeleteMeSection = () => {
    if (!keycloak.authenticated) return null;

    const handleDelete = async (profile: Profile) => {
      setDeletingProfileId(profile.externalId);
      try {
        await deleteMe({
          credential: selectedCredential,
          externalId: profile.externalId,
          type: profile.type,
        });

        // Refresh profiles after successful deletion
        const fetchedProfiles = await getProfiles({
          credential: selectedCredential,
          subject: keycloak.tokenParsed?.sub,
        });
        setProfiles(fetchedProfiles);
      } catch (error) {
        console.error("Error deleting profile:", error);
      } finally {
        setDeletingProfileId(null);
      }
    };

    return (
      <div className="mt-6">
        <b className="text-white">Delete Me Section</b>
        {isLoadingProfiles ? (
          <div className="text-white">Loading profiles...</div>
        ) : profiles.length === 0 ? (
          <div className="text-white">No profiles found</div>
        ) : (
          <div className="space-y-4 mt-4">
            {profiles.map((profile) => (
              <div
                key={profile.externalId}
                className="bg-[#222222] rounded-md p-4 flex justify-between items-center"
              >
                <div>
                  <div className="text-white font-semibold">{profile.name}</div>
                  <div className="text-gray-400 text-sm">{profile.type}</div>
                  <div className="text-gray-400 text-xs">
                    {profile.externalId}
                  </div>
                </div>
                <button
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={() => handleDelete(profile)}
                  disabled={deletingProfileId === profile.externalId}
                >
                  {deletingProfileId === profile.externalId ? (
                    <div className="flex gap-2 items-center">
                      <i className="fas fa-spinner fa-spin"></i>
                      Deleting...
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const Profile = () => {
    return (
      keycloak.authenticated && (
        <div>
          <b className="text-white">Token info</b>
          <pre className="text-white rounded-md border-none bg-[#222222] px-3 py-3">
            {JSON.stringify(keycloak.tokenParsed, null, 2)}
          </pre>
          <div className="text-white break-all rounded-md border-none bg-[#222222] px-3 py-3">
            {keycloak.token}
          </div>
          {/* <div className="text-white break-all rounded-md border-none bg-[#222222] px-3 py-3">
            {keycloak.idToken}
          </div> */}
          <DeleteMeSection />
        </div>
      )
    );
  };

  return (
    <ContentWrapper>
      <InnerContentWrapper>
        <Title>Login</Title>
        <FormWrapper>
          <div className="mb-2">
            <Configuration />
          </div>
          {!keycloak.authenticated && <LoginButton />}
          {keycloak.authenticated && (
            <div className="flex justify-between">
              <div className="flex gap-2 flex-shrink">
                <LogoutButton />
                <StepUpButton />
              </div>
              <IngestButton />
            </div>
          )}
          <Profile />
        </FormWrapper>
      </InnerContentWrapper>
    </ContentWrapper>
  );
};

export default Content;
